import config from 'config';
import vsfI18n from '@vue-storefront/core/i18n';

import { BaseComponent, Component, Prop } from '../';
import { ILocaleFormat } from '../../../common/lib/config/types/zento/locales';
import type { IZentoConfig } from '../../../common/stores';

import { Format } from './inline';
import type { IFormatCurrency } from './FormatCurrency.d';

@Component({})
export class FormatCurrency extends BaseComponent<IFormatCurrency, unknown> {
  @Prop() code?: ILocaleFormat['currency']['code'];

  @Prop() sign?: ILocaleFormat['currency']['sign'];

  @Prop() symbolUse?: ILocaleFormat['currency']['symbol']['use'];
  @Prop() symbolPlacement?: ILocaleFormat['currency']['symbol']['placement'];
  @Prop() symbolPrefix?: ILocaleFormat['currency']['symbol']['prefix'];
  @Prop() symbolSuffix?: ILocaleFormat['currency']['symbol']['suffix'];

  @Prop() thousandsSeparator?: ILocaleFormat['number']['thousandsSeparator'];
  @Prop() decimalsSeparator?: ILocaleFormat['number']['decimalsSeparator'];
  @Prop() precision?: ILocaleFormat['number']['precision'];

  @Prop() value!: number;

  /**
   * Determines price measure
   */
  @Prop({ type: String })
  measure?: string;

  /**
   * Get current locale default representation
   */
  private get locale() {
    return (config as IZentoConfig).zento.locales[vsfI18n.locale];
  }

  render() {
    const placement = this.locale.format.currency.symbol.placement;
    const decimalsConf = this.locale.format.currency.number;
    const decimalsSup = 'decimalsSup' in decimalsConf ? decimalsConf.decimalsSup : false;
    const decimalsSub = 'decimalsSub' in decimalsConf ? decimalsConf.decimalsSub : false;
    const decimals = this.value?.toFixed(decimalsConf.precision)?.split('.')[1];

    return this.value !== undefined ? (
      <span>
        {Format.currency(this.value, {
          enableSign: placement === 'before' || (!decimalsSup && !decimalsSub),
          enableValue: true,
          code: this.code,
          sign: this.sign,
          symbol: {
            placement: this.symbolPlacement,
            use: this.symbolUse,
            prefix: this.symbolPrefix,
            suffix: this.symbolSuffix,
          },
          number: {
            decimalsSeparator: this.decimalsSeparator,
            precision: !decimalsSup && !decimalsSub ? decimalsConf.precision : 0,
            thousandsSeparator: this.thousandsSeparator,
          },
        })}

        {decimalsSup ? <sup>{decimals?.toString()}</sup> : decimalsSub ? <sub>{decimals?.toString()}</sub> : null}

        {placement === 'after' && (decimalsSup || decimalsSub)
          ? Format.currency(this.value, {
              code: this.code,
              sign: this.sign,
              enableSign: true,
              enableValue: false,
              symbol: {
                placement: this.symbolPlacement,
                use: this.symbolUse,
                prefix: this.symbolPrefix,
                suffix: this.symbolSuffix,
              },
            })
          : null}

        {this.measure ? (
          <span>
            {'/ '}
            {this.measure}
          </span>
        ) : null}
      </span>
    ) : null;
  }
}
